import React from 'react';

import {ErrorBoundary} from 'react-error-boundary';

import {TenantProfilePictureImage} from './TenantProfilePictureImage';
import {IProfilePictureProps, ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';

export type ProfilePictureSharedProps = Omit<IProfilePictureProps, 'id' | 'url' | 'alt' | 'name'>;

export const TenantProfilePicture = ({imgClassName, ...rest}: ProfilePictureSharedProps) => (
    <ErrorBoundary
        fallback={(
            <ProfilePicture alt="loading..." {...rest} />
        )}
    >
        <React.Suspense
            fallback={(
                <ProfilePicture alt="loading..." {...rest} />
            )}
        >
            <TenantProfilePictureImage imgClassName={imgClassName} {...rest} />
        </React.Suspense>
    </ErrorBoundary>
);
